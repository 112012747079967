import React from 'react';
import { BadgeProps, Box, Badge, useTheme, badgeClasses, BoxProps } from '@mui/material';
import { KERij } from '../AppIcon';

export interface ListNotificationItemIconProps extends Omit<BoxProps, 'children'> {
  badgeProps: Pick<BadgeProps, 'invisible'>;
}

export function ListNotificationItemIcon(props: ListNotificationItemIconProps) {
  const { badgeProps, ...boxProps } = props;

  const theme = useTheme();

  return badgeProps.invisible ? (
    <Box {...boxProps}>
      <KERij
        sx={{
          height: theme.spacing(6),
          width: theme.spacing(6),
          color: theme.md3.sys.color.onSurfaceVariant,
        }}
      />
    </Box>
  ) : (
    <Box {...boxProps}>
      <Badge
        variant="dot"
        invisible={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={(theme) => ({
          [`& .${badgeClasses.badge}`]: {
            height: theme.spacing(1.5),
            width: theme.spacing(1.5),
            borderRadius: '100%',
            left: theme.spacing(-1.75),
            top: theme.spacing(3),
            backgroundColor: theme.palette.aquaSerenity[600],
          },
        })}
      >
        <KERij
          sx={{
            height: theme.spacing(6),
            width: theme.spacing(6),
            color: theme.md3.sys.color.onSurfaceVariant,
          }}
        />
      </Badge>
    </Box>
  );
}

import React from 'react';
import { Box, ListItemButton, MenuItem, Typography, TypographyProps, useTheme } from '@mui/material';
import { ListNotificationItemIcon } from './ListNotificationItemIcon';
import { Spacer } from '../Spacer';
import moment from 'moment';
import { NotificationVariant } from './type';

export interface ListNotificationItemProps extends Omit<TypographyProps, 'variant'> {
  variant: NotificationVariant;
  title: string;
  date: string;
  hasRead: boolean;
  onClick?: () => void;
}

export function ListNotificationItem(props: ListNotificationItemProps) {
  const { children, variant, hasRead, onClick, date, title, ...typographyProps } = props;

  const theme = useTheme();

  const renderItem = () => (
    <Box display={'flex'} gap={2} p={4} pt={2} pb={2} width={'100%'}>
      <ListNotificationItemIcon badgeProps={{ invisible: hasRead }} />

      <Box whiteSpace={'break-spaces'}>
        <Typography fontSize={16} lineHeight={1.5}>
          {title}
        </Typography>

        <Spacer spacing={1} />
        <Typography fontSize={12} lineHeight={'normal'} sx={{ color: theme.md3.sys.color.onSurfaceVariant }}>
          {moment(date).format('ll')}
        </Typography>
        <Spacer spacing={1} />

        <Typography fontSize={14} lineHeight={1.4} {...typographyProps}>
          {children}
        </Typography>

        <Spacer spacing={1} />
      </Box>
    </Box>
  );

  return variant === NotificationVariant.List ? (
    <ListItemButton
      onClick={onClick}
      sx={{
        padding: 0,
        backgroundColor: hasRead ? '#fff' : theme.palette.aquaSerenity[50],
      }}
    >
      {renderItem()}
    </ListItemButton>
  ) : (
    <MenuItem
      onClick={onClick}
      sx={{
        padding: 0,
        maxWidth: theme.spacing(49),
        backgroundColor: hasRead ? '#fff' : theme.palette.aquaSerenity[50],
      }}
    >
      {renderItem()}
    </MenuItem>
  );
}

import React from 'react';
import { NavbarMobileTabProps } from './NavbarMobileTab';
import { KERij, NotificationBadge } from '@/components/AppIcon';
import { Feature, UserInterface } from '@/types';
import FolderOpenOutlined from '@mui/icons-material/FolderOpenOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

/**
 * Défini le contenu du menu Mobile salarié
 */
export function defineMenu(
  user: UserInterface | null,
  options: { hasUnread: boolean; isLogin: boolean },
): NavbarMobileTabProps[] {
  return [
    ...(options.isLogin
      ? [
          {
            label: 'Services',
            feature: 'ServiceManagement' as keyof Feature,
            to: options.isLogin ? '/services' : '/login',
            icon: <SearchOutlined />,
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Événements',
            to: options.isLogin ? '/evenements' : '/login',
            icon: <FolderOpenOutlined />, // FIXME
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Démarches',
            to: options.isLogin ? '/demarches' : '/login',
            icon: <KERij sx={{ padding: '2px' }} />, // FIXME
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Notifications',
            to: '/notifications',
            icon: <NotificationBadge badgeProps={{ invisible: !options.hasUnread }} position={{ top: 2, right: 1 }} />,
          },
        ]
      : []),
  ];
}

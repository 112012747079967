import React from 'react';
import { NotificationVariant } from './type';
import { Notification, useAppDispatch } from '@/store';
import type { NotificationFilterType } from '../FilterNotification';
import { ListNotificationTitle } from './ListNotificationTitle';
import { ListNotificationItem } from './ListNotificationItem';
import { ListNotificationDivider } from './ListNotificationDivider';
import { useNavigate } from 'react-router-dom';

export interface ListNotificationValue {
  id: string;
  content: {
    message: Array<string>;
    title: string;
    to: string;
  };
  date: string;
  hasRead: boolean;
  age?: number;
}

export interface ListNotificationContentSectionProps {
  values: Array<ListNotificationValue>;
  title?: string;
  onClickItem?: () => void;

  filter: NotificationFilterType;
  variant: NotificationVariant;
}

export function ListNotificationContentSection(props: ListNotificationContentSectionProps) {
  const { values, variant, title, onClickItem } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const markAsRead = (id: string) => dispatch(Notification.markAsRead({ id }));

  return (
    <React.Fragment>
      {values.length > 0 && title != null && <ListNotificationTitle variant={variant}>{title}</ListNotificationTitle>}
      {values.map((notification, index) => (
        <>
          <ListNotificationItem
            key={`NotificationItemToday__${index}`}
            variant={variant}
            title={notification.content.title}
            date={notification.date}
            hasRead={notification.hasRead}
            onClick={() => {
              onClickItem?.();
              markAsRead(notification.id);
              navigate(notification.content.to);
            }}
          >
            {notification.content.message.join('\n')}
          </ListNotificationItem>
          {index === values.length - 1 ? null : <ListNotificationDivider variant={variant} />}
        </>
      ))}
    </React.Fragment>
  );
}

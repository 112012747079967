/* eslint-disable unicorn/prefer-query-selector */
import React, { useState, useEffect, useMemo } from 'react';
import ImgHeader from '@/assets/header-services-iconkerij.svg';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore FIXME: no type definition :(
import TypeWriterEffect from 'react-typewriter-effect';
import { StatusCodes } from 'http-status-codes';
import { tous } from '@/components/ServiceThemeIcon';
import { employeeDecodeToken, fetchService } from '../../../services/employee';
import isAuthorized from '../../../hooks/isAuthorized';
import { Service, ServiceTheme, ServiceThemeIcon, ServiceThemeLabel } from '@/types';
import { ServiceList } from '@/components/ServicesList';
import { FilterInput, FilterInputElement } from '@/components/FilterInput';
import { Spacer } from '@/components/Spacer';
import { Box, Paper, Typography } from '@mui/material';
import { SearchField } from '@/components/DashboardRH/shared/components/Form';

export const serviceMaxPerPage = 10;

export function ServicesContent() {
  const themes: FilterInputElement<ServiceTheme | undefined>[] = [
    { value: undefined, title: 'Tous', icon: tous },
    ...Object.values(ServiceTheme).map((theme) => ({
      value: theme,
      title: ServiceThemeLabel[`${theme}`],
      icon: ServiceThemeIcon[`${theme}`],
    })),
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [valSearch, setValSearch] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [listService, setListService] = useState<Service[]>([]);
  const [theme, setTheme] = useState<ServiceTheme | undefined>(
    (searchParams.get('theme') as ServiceTheme) ?? undefined,
  );
  const [decode, setDecode] = useState({
    idEmployeeInformation: '',
    idAbsence: '',
    typeNotification: '',
  });

  useEffect(() => {
    if (token) {
      employeeDecodeToken({ token }).then((val) => {
        if (val.status === StatusCodes.OK) {
          setDecode(val.data.decode);
        }
      });
    }
  }, []);

  const [access, setAccess] = useState(true);
  const handleChange = (value: string) => {
    setValSearch(value);
  };

  const getServicePages = async (theme: ServiceTheme | undefined, page = 1) => {
    if (page === 1) setListService([]);

    const { data, status } = await fetchService({ theme, page, per_page: serviceMaxPerPage });
    if (status === 200) setAccess(true);
    setListService((values) => [...values, ...data.services]);

    if (data.services.length === serviceMaxPerPage) await getServicePages(theme, page + 1);
  };

  const getServicesFiltered = async (theme: ServiceTheme | undefined) => {
    setIsLoadingServices(true);
    await getServicePages(theme, 1);
    setIsLoadingServices(false);
    setLoadingState(true);
  };

  useEffect(() => {
    if (isAuthorized(location.pathname)) {
      getServicesFiltered(theme);
    }
  }, [theme]);

  const filteredList = useMemo(
    () =>
      valSearch == null || valSearch.trim() === ''
        ? listService
        : listService.filter((service) => service.title.toLowerCase().includes(valSearch.toLowerCase())),
    [listService, valSearch],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      {access ? (
        <div className="header-service">
          <Box>
            <h1 className="declaration-h1">
              <span>
                <TypeWriterEffect
                  textStyle={{
                    color: '#40C9C9',
                    fontWeight: 1000,
                  }}
                  startDelay={0}
                  cursorColor="#40C9C9"
                  multiText={['Des outils', 'Des services', 'Des solutions', "Des programmes d'accompagnement"]}
                  multiTextDelay={2000}
                  typeSpeed={50}
                  multiTextLoop
                />
              </span>{' '}
              pour vous soutenir
            </h1>
            <img alt="kerij" className="img-header-service" src={ImgHeader} />
          </Box>

          <Box>
            <Paper elevation={3} sx={(theme) => ({ padding: theme.spacing(2.5) })}>
              <Typography className="btn-service-sub-search" fontWeight={600}>
                Chercher un service
              </Typography>

              <Box className="btn-service-sub-search">
                <Spacer size="small" />
              </Box>

              <SearchField placeholder="Quel est votre besoin ?" onChange={(_) => handleChange(_)} fullWidth noHelper />
            </Paper>
          </Box>
        </div>
      ) : (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">
            Attention ! Vous n&apos;avez pas l&apos;autorisation d&apos;accéder à cette page
          </h1>
        </div>
      )}

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1" style={{ padding: '1rem', zIndex: 1 }}>
          <FilterInput
            items={themes}
            value={theme}
            onChange={(_, newTheme) => setTheme(newTheme)}
            disabled={isLoadingServices}
          />

          <Spacer />

          <ServiceList
            values={filteredList}
            isLoading={isLoadingServices}
            onClick={(service) => navigate(`/services/${service.id}`)}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { DeclarationFormControl } from './type';
import { useToast } from '@/components/Toast';
import { useAppSelector, AccountContext } from '@/store';
import { usePlatform } from '@/utils/platform.utils';
import { DocumentStatus } from '@/types';
import { getTypeAbsence } from '@/features/declaration/services/get_type_absence.service';
import {
  verifyAbsenceDocument,
  verifyAbsenceDocumentResult,
} from '@/features/declaration/services/verify_absence_document.service';
import { InfoBox, KerijLoadingOCR } from '@/components/base';
import { Avatar, Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import remiseDoc from '../../../assets/remise-doc.png';
import imgDemat from '../../../assets/Arret_demat.jpg';
import { Spacer } from '@/components/Spacer';
import { InputFileHidden, InputFileScanbot } from '@/components/InputFile';
import { ActionButton, ActionButtonProps } from '@/components/ActionButton';
import { useAppVariant } from '@/hooks/useAppVariant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export interface DeclarationFormDocumentProps {
  formControl: DeclarationFormControl;
  type: string | null;
  edit?: boolean;
  onDocumentUpload: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function DeclarationFormDocument(props: DeclarationFormDocumentProps) {
  const { formControl, type, edit, onDocumentUpload } = props;

  const platform = usePlatform();
  const toast = useToast();
  const user = useAppSelector(AccountContext.getUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [closeLoading, setCloseLoading] = useState(false);

  const [openScan, setOpenScan] = React.useState<boolean>(false);
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const onCloseLoader = async () => {
    setIsLoading(false);

    if (formControl[0].document.data == null)
      return rejectDocument("Nous n'avons pas réussi à valider votre document, vérifiez la qualité de votre document");

    onDocumentUpload();
  };

  const handleNoFileClick = () => {
    formControl[1]((model) => ({
      ...model,
      document: { no_file: true, file: null, data: null, status: null },
    }));
  };

  const rejectDocument = (message: string) => {
    setIsLoading(false);
    toast.present({ message, severity: 'error' });
    formControl[1]((model) => ({
      ...model,
      document: {
        file: null,
        no_file: false,
        data: { documentStatus: DocumentStatus.REJECTED },
        status: DocumentStatus.REJECTED,
      },
    }));
  };

  const handleVerification = async (
    verificationFunction: () => Promise<
      { success: true; data: { transactionId: string } } | { success: false; error: string }
    >,
  ) => {
    try {
      setIsLoading(true);
      const valueDocument = await verificationFunction();

      if (user == null) {
        toast.present({ message: `Impossible de récupérer l'utilisateur`, severity: 'error' });
        return;
      }

      if (valueDocument.success) {
        const typeAbsence = await getTypeAbsence({
          description: type === 'HO' ? '2' : '1',
          description_1: type === 'HO' ? undefined : type === 'MA' ? '1' : '2',
          description_2: formControl[0].type ?? undefined,
        });

        const result = await verifyAbsenceDocumentResult({
          id_employee_information: user.employee_information.id,
          date_end: formControl[0].date.date_end ?? '',
          date_start: (formControl[0].date.last_day_work ?? formControl[0].date.date_start) || '',
          id_type_absence: typeAbsence.id ?? (type === 'HO' ? '2' : '1'),
          transactionId: valueDocument.data.transactionId,
        });

        if (result.success === false) return rejectDocument(result.error);
        else {
          if (result.data.data.documentStatus === DocumentStatus.REJECTED)
            return rejectDocument("Votre document n'a pas pu être validé");
          else {
            formControl[1]((model) => ({
              ...model,
              document: {
                ...model.document,
                ...result.data,
                status: result.data.data.documentStatus,
              },
            }));
            setCloseLoading(true);
          }
        }
        return;
      } else return rejectDocument('Une erreur est survenue lors de la validation, vérifier votre connexion');
    } catch {
      setIsLoading(false);
      formControl[1]((model) => ({
        ...model,
        document: {
          file: null,
          no_file: false,
          data: { documentStatus: DocumentStatus.REJECTED },
          status: DocumentStatus.REJECTED,
        },
      }));
      toast.present({ message: `Une erreur est survenue à la validation du document`, severity: 'error' });
    }
  };

  const handleSubmit = async () => {
    if (user && formControl[0].document.file) {
      await handleVerification(async () => {
        return await verifyAbsenceDocument({ file: formControl[0].document.file as File });
      });
    }
  };

  React.useEffect(() => {
    if (formControl[0].document.file) handleSubmit();
  }, [formControl[0].document.file]);

  return isLoading ? (
    <KerijLoadingOCR close={closeLoading} onClose={onCloseLoader} />
  ) : (
    <Box>
      <DocumentCard
        primary="Vous disposer d’un arrêt au format papier"
        secondary="Veuillez utiliser notre outil de scan en cliquant sur le bouton ci-dessous 'Scanner mon arrêt'."
        src={remiseDoc}
        tooltip={
          platform.isMobile ? null : (
            <Box>
              <Typography fontSize={12} fontWeight="bold" mb={1}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
                Utilisez l&apos;appareil photo de votre smartphone
              </Typography>
              <Typography fontSize={12}>
                Si vous disposez d’un arrêt de travail au format papier, nous vous recommandons de saisir votre absence
                sur votre SMARTPHONE.
              </Typography>
            </Box>
          )
        }
        actionButtonProps={{
          label: 'Scanner mon arrêt',
          disabled: !platform.isMobile,
          onClick: () => setOpenScan(true),
        }}
      />

      <InputFileScanbot
        isOpen={openScan}
        onClose={() => setOpenScan(false)}
        onChange={(_, files) => {
          formControl[1]((model) => ({
            ...model,
            document: {
              file: files.at(0) ?? null,
              no_file: false,
              data: null,
              status: null,
            },
          }));
        }}
      />

      <Spacer spacing={4} />

      <DocumentCard
        primary="Vous disposer d’un arrêt dématérialisé"
        secondary="Veuillez déposer votre document, au format de pdf de préférence."
        src={imgDemat}
        actionButtonProps={{
          label: 'Déposer mon justificatif',
          onClick: () => inputFileRef.current?.click(),
        }}
      />
      <InputFileHidden
        ref={inputFileRef}
        onChange={(_, files) => {
          formControl[1]((model) => ({
            ...model,
            document: {
              file: files.at(0) ?? null,
              no_file: false,
              data: null,
              status: null,
            },
          }));
        }}
        accept="image/jpeg, image/png, application/pdf"
        capture={false}
        name="document"
        id="file"
      />

      {!edit && (
        <Box pt={2} pb={2}>
          <ActionButton
            fullWidth
            color="error"
            variant={'outlined'}
            label="Je n'ai pas de justificatif"
            onClick={handleNoFileClick}
          />
        </Box>
      )}

      {formControl[0].document.no_file && (
        <InfoBox type="error">
          <strong>Attention,</strong> vous avez besoin d’un justificatif pour bénéficier de vos indemnités. Il vous sera
          demandé dans les 48h suivant cette déclaration.
        </InfoBox>
      )}
    </Box>
  );
}

const DocumentCard = (cardProps: {
  primary: string;
  secondary: string;
  src: string;
  tooltip?: React.ReactNode;
  actionButtonProps: Pick<ActionButtonProps, 'label' | 'onClick' | 'disabled'>;
}) => {
  const appVariant = useAppVariant();

  const renderButton = (children: React.ReactElement) =>
    cardProps.tooltip == null ? (
      children
    ) : (
      <Tooltip title={cardProps.tooltip} placement="top-start">
        {children}
      </Tooltip>
    );

  return (
    <Card color="surface">
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'} gap={2}>
          <Box>
            <Box display={'flex'} flexDirection={'column'} height={80}>
              <Typography mt={'auto'} mb={'auto'} fontSize={16} fontWeight={600} lineHeight={1.5}>
                {cardProps.primary}
              </Typography>
            </Box>
            {appVariant.medium && (
              <Typography fontSize={14} lineHeight={1.4} pt={2} pb={2}>
                {cardProps.secondary}
              </Typography>
            )}
          </Box>
          <Avatar size="xxxlarge" src={cardProps.src} sx={{ background: '#fff' }} />
        </Box>

        {!appVariant.medium && (
          <Typography fontSize={14} lineHeight={1.4} pb={2} pt={2}>
            {cardProps.secondary}
          </Typography>
        )}

        {renderButton(
          <Box pt={2} pb={2}>
            <ActionButton color="primary" {...cardProps.actionButtonProps} />
          </Box>,
        )}
      </CardContent>
    </Card>
  );
};

import React from 'react';
import { Badge, badgeClasses, SvgIconProps, BadgeProps } from '@mui/material';
import { NotificationsOutlined } from '@mui/icons-material';

export interface NotificationBadgeProps extends SvgIconProps {
  badgeProps?: BadgeProps;
  position?: { right: number; top: number };
}

export function NotificationBadge(props: NotificationBadgeProps) {
  const { badgeProps, position, ...iconProps } = props;

  return (
    <Badge
      variant="dot"
      sx={(theme) => ({
        [`& .${badgeClasses.badge}`]: {
          right: theme.spacing(position?.right ?? 1),
          top: theme.spacing(position?.top ?? 1),
          backgroundColor: theme.palette.infernoRed[500],
        },
      })}
      {...badgeProps}
    >
      <NotificationsOutlined {...iconProps} />
    </Badge>
  );
}

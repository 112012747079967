import React from 'react';
import classNames from 'clsx';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import { NotificationVariant } from './type';
import { ListNotificationSection } from './ListNotificationSection';

const componentName = 'ListNotificationTitle';

export interface ListNotificationTitleProps extends Omit<TypographyProps, 'variant'> {
  variant: NotificationVariant;
}

export function ListNotificationTitle(props: ListNotificationTitleProps) {
  const { children, variant, className, ...typographyProps } = props;

  const theme = useTheme();

  return (
    <ListNotificationSection variant={variant} boxProps={{ p: 4 }}>
      <Typography
        className={classNames(componentName, className)}
        fontSize={24}
        fontWeight={600}
        lineHeight={'normal'}
        whiteSpace={'break-spaces'}
        sx={{ color: theme.md3.sys.color.onSurfaceVariant }}
        {...typographyProps}
      >
        {children}
      </Typography>
    </ListNotificationSection>
  );
}

import React from 'react';
import { Box, Divider, ListItem, ListItemProps } from '@mui/material';
import { NotificationVariant } from './type';
import { Spacer } from '../Spacer';
import { ListNotificationSection, ListNotificationSectionProps } from './ListNotificationSection';

interface ListNotificationDividerListProps {
  variant: typeof NotificationVariant.List;
  props?: Omit<ListItemProps, 'variant' | 'children'>;
}
interface ListNotificationDividerMenuProps {
  variant: typeof NotificationVariant.Menu;
  props?: Omit<ListNotificationSectionProps, 'variant' | 'children'>;
}

export type ListNotificationDividerProps = ListNotificationDividerMenuProps | ListNotificationDividerListProps;

export function ListNotificationDivider(props: ListNotificationDividerProps) {
  return props.variant === NotificationVariant.List ? (
    <ListItem {...props.props} sx={{ p: 0, pl: 2, pr: 2 }}>
      <Box width={'100%'} pl={2} pr={2}>
        <Divider />
      </Box>
    </ListItem>
  ) : (
    <ListNotificationSection variant="menu" {...props.props}>
      <Box width={'100%'} pl={4} pr={4}>
        <Divider />
      </Box>
    </ListNotificationSection>
  );
}

import React from 'react';
import classNames from 'clsx';
import { List, ListProps, Menu, MenuProps, useTheme } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { NotificationVariant } from './type';

const componentName = 'ListNotification';

type ListNotificationMenuProps = {
  variant: typeof NotificationVariant.Menu;
  menuProps: Omit<MenuProps, 'children'>;
  children: MenuProps['children'];
};
type ListNotificationListProps = {
  variant: typeof NotificationVariant.List;
  listProps?: Omit<ListProps, 'children'>;
  children: ListProps['children'];
};

export type ListNotificationProps = ListNotificationMenuProps | ListNotificationListProps;

export function ListNotification(props: ListNotificationProps) {
  const { children, ...elementProps } = props;

  const theme = useTheme();
  const appVariant = useAppVariant();

  return elementProps.variant === NotificationVariant.List ? (
    <List
      className={classNames(componentName)}
      sx={{
        p: 0,
        pb: appVariant.medium ? undefined : 10,
        position: appVariant.medium ? undefined : 'absolute',
        left: appVariant.medium ? undefined : 0,
        right: appVariant.medium ? undefined : 0,

        width: appVariant.medium ? `calc(100% + ${theme.spacing(4)})` : undefined,
        marginLeft: appVariant.medium ? theme.spacing(-4) : undefined,
      }}
      {...elementProps.listProps}
    >
      {children}
    </List>
  ) : (
    <Menu
      className={classNames(componentName)}
      sx={{ marginTop: 0.5 }}
      slotProps={{
        paper: { sx: { maxWidth: theme.spacing(49), paddingBottom: 3 } },
      }}
      MenuListProps={{
        'aria-labelledby': 'lock-button',
        role: 'listbox',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...elementProps.menuProps}
    >
      {children}
    </Menu>
  );
}

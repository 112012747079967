import React from 'react';
import classNames from 'clsx';
import { Box, Card, type CardProps, CardContent } from '@mui/material';
import { Spacer } from '../Spacer';
import { EmployeeAbsenceCardState } from './EmployeeAbsenceCardState';
import { EmployeeAbsenceCardType } from './EmployeeAbsenceCardType';
import { EmployeeAbsenceCardResume } from './EmployeeAbsenceCardResume';
import { EmployeeAbsenceCardDocument } from './EmployeeAbsenceCardDocument';
import { Absence, AbsenceStatus, DocumentAbsence } from '@/types';
import { EmployeeAbsenceCardActions } from './EmployeeAbsenceCardActions';

const componentName = 'EmployeeAbsenceCard';

export interface EmployeeAbsenceCardProps extends Omit<CardProps, 'children'> {
  absence: Absence;
  absenceDocument?: DocumentAbsence;
}

export function EmployeeAbsenceCard(props: EmployeeAbsenceCardProps) {
  const { absence, absenceDocument, className, ...cardProps } = props;

  const disabledAction = absence.status === AbsenceStatus.FINISHED || absence.status === AbsenceStatus.DELETED;

  return (
    <Card className={classNames(componentName, className)} color="surface" {...cardProps}>
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'} gap={3}>
          <EmployeeAbsenceCardState value={absence} isValid={cardProps.color === 'success'} />
          <EmployeeAbsenceCardType value={absence} />
        </Box>

        <Spacer spacing={2} />
        <EmployeeAbsenceCardResume value={absence} />

        {absenceDocument && <EmployeeAbsenceCardDocument value={absenceDocument} />}

        {!disabledAction && <EmployeeAbsenceCardActions value={absence} hasDocument={absenceDocument != null} />}
      </CardContent>
    </Card>
  );
}

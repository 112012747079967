import React from 'react';
import { Box, ListItemButton, MenuItem, Skeleton, Typography, useTheme } from '@mui/material';
import { ListNotificationItemIcon } from './ListNotificationItemIcon';
import { Spacer } from '../Spacer';
import { NotificationVariant } from './type';

export interface ListNotificationItemSkeletonProps {
  variant: NotificationVariant;
}

export function ListNotificationItemSkeleton(props: ListNotificationItemSkeletonProps) {
  const { variant } = props;
  const theme = useTheme();

  const renderItem = () => (
    <Box display={'flex'} gap={2} p={4} pt={2} pb={2} width={'100%'}>
      <ListNotificationItemIcon badgeProps={{ invisible: true }} />

      <Box whiteSpace={'break-spaces'} width={'100%'}>
        <Typography fontSize={16} lineHeight={1.5}>
          <Skeleton width={180} />
        </Typography>

        <Spacer spacing={1} />
        <Typography fontSize={12} lineHeight={'normal'} sx={{ color: theme.md3.sys.color.onSurfaceVariant }}>
          <Skeleton width={80} />
        </Typography>
        <Spacer spacing={1} />

        <Typography fontSize={14} lineHeight={1.4}>
          <Skeleton width={'100%'} />
          <Skeleton width={'80%'} />
        </Typography>

        <Spacer spacing={1} />
      </Box>
    </Box>
  );

  return variant === NotificationVariant.List ? (
    <ListItemButton
      sx={{
        padding: 0,
        backgroundColor: '#fff',
      }}
    >
      {renderItem()}
    </ListItemButton>
  ) : (
    <MenuItem
      sx={{
        padding: 0,
        maxWidth: theme.spacing(49),
        backgroundColor: '#fff',
      }}
    >
      {renderItem()}
    </MenuItem>
  );
}

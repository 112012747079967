import React, { useState } from 'react';
import classNames from 'clsx';
import { Box, BoxProps, useTheme } from '@mui/material';
import { ActionButton, ActionButtonProps } from '../ActionButton';

const componentName = 'FilterNotification';

export interface FilterNotificationProps extends Omit<BoxProps, 'children'> {
  onFilter?: (type: NotificationFilterType) => void;
}

export function FilterNotification(props: FilterNotificationProps) {
  const { className, onFilter, ...boxProps } = props;

  const theme = useTheme();
  const [filterType, setFilterType] = useState<NotificationFilterType>('all');

  const selectedStyle: ActionButtonProps['style'] = {
    backgroundColor: theme.md3.sys.color.surface,
    color: theme.md3.sys.color.onSurfaceVariant,
    borderColor: theme.shape.borderColorVariant,
  };

  const notSelectedStyle: ActionButtonProps['style'] = {
    backgroundColor: '#fff',
    color: theme.md3.sys.color.onSurfaceVariant,
    borderColor: '#fff',
  };

  const renderButton = (type: NotificationFilterType, label: string) => (
    <ActionButton
      label={label}
      size="small"
      color={filterType === type ? 'secondary' : 'tertiary'}
      sx={{
        border: '1px solid',
        ':hover': {
          backgroundColor: `${filterType === type ? theme.md3.sys.color.surface : theme.palette.grey[100]} ! important`,
        },
      }}
      style={filterType === type ? selectedStyle : notSelectedStyle}
      onClick={() => {
        setFilterType(type);
        onFilter?.(type);
      }}
    />
  );

  return (
    <Box
      className={classNames(componentName, className)}
      paddingTop={2}
      paddingBottom={2}
      display={'flex'}
      gap={2}
      {...boxProps}
    >
      {renderButton(NotificationFilterType.All, 'Toutes')}
      {renderButton(NotificationFilterType.News, 'Non lues')}
    </Box>
  );
}

export const NotificationFilterType = {
  All: 'all',
  News: 'news',
} as const;
export type NotificationFilterType = (typeof NotificationFilterType)[keyof typeof NotificationFilterType];

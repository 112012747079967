import React from 'react';
import classNames from 'clsx';
import { DESKTOP_NAV_HEIGHT, Navbar, NavbarMobile, NavbarMobileMenu, useNavbarMobile } from '@/components/Navbar';
import { Box, styled, type BoxProps } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { AppLayoutMobileBack, AppLayoutMobileBackProps, MOBILE_NAV_HEIGHT } from './AppLayoutMobileBack';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import './AppLayout.scss';
import { useAuthentication } from '@/hooks/useAuthentication';

const componentName = 'AppLayout';

export interface AppLayoutProps extends BoxProps {
  /**
   * ### Permet d'afficher un bouton de retour sur mobile
   * default: false
   * - true : navigue vers la page précédente.
   */
  mobileBackButton?: true;
  /**
   * ### Affiche la navbar mobile et desktop
   * default: true
   */
  navbar?: boolean | 'mobile-only' | 'desktop-only';
  /**
   * Footer slot element
   */
  footer?: React.ReactNode;

  // Propriétés accessibles accessibles
  AppLayoutMobileBackProps?: Pick<AppLayoutMobileBackProps, 'sx' | 'variant'>;
}

export const AppLayout = (props: AppLayoutProps) => {
  const appVariant = useAppVariant();
  const navbarMobile = useNavbarMobile();

  const {
    mobileBackButton,
    navbar = true,
    className,
    children,
    AppLayoutMobileBackProps,
    footer,
    ...otherProps
  } = props;

  const modal = useMUIDialogController();
  const paddingBottom = navbarMobile ? MOBILE_NAV_HEIGHT : 0;
  const paddingTop = navbarMobile || !navbar ? 0 : DESKTOP_NAV_HEIGHT;

  return (
    <Box minHeight={'100vh'} {...otherProps} className={classNames(componentName, className)}>
      {(navbar === true || navbar === 'desktop-only') && (
        <Box className={`${componentName}__Header`} component={'header'}>
          <Navbar />
        </Box>
      )}

      <ContentBox component={'main'} className={`${componentName}__Content`} style={{ paddingTop }}>
        <Box
          className={`${componentName}__Container`}
          style={{ paddingBottom }}
          display={'flex'}
          flexDirection={'column'}
        >
          {mobileBackButton && !appVariant.medium && <AppLayoutMobileBack {...AppLayoutMobileBackProps} />}
          {children}
        </Box>
      </ContentBox>

      <Box component={'footer'} className={`${componentName}__Footer`} sx={{ zIndex: 1000 }}>
        {(navbar === true || navbar === 'mobile-only') && <NavbarMobile menuController={modal.control} />}
        {(navbar === true || navbar === 'mobile-only') && <NavbarMobileMenu {...modal.getRootProps()} />}
        {footer}
      </Box>
    </Box>
  );
};

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
}));
